import { Close } from '@mui/icons-material';

import CenteredProgress from '@shared/centered-progress';
import { InputBlock } from '@shared/input-block';
import Required from '@shared/required';
import { Note, SpanLabel } from '@shared/styled';

import { useGeolocation } from '~/hooks/events/useGeolocation';

import styles from './styles.module.css';

import { withStyledComponents } from './with-styled-component';

const FakeInput = withStyledComponents(styles.FakeInput, 'div');
const ProgressWrapper = withStyledComponents(styles.AddressInputProgressWrapper, 'div');
const Wrapper = withStyledComponents(styles.AddressInputWrapper, 'div');
const SuggestionWrapper = withStyledComponents(styles.SuggestionWrapper, 'div');
const Suggestion = withStyledComponents(styles.Suggestion, 'button');

export const AddressInput = ({
  value: selectedLocation,
  updateValue,
  note,
  label,
  minimal,
  disabled,
  required,
  placeholder,
}: {
  note?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  minimal?: boolean;
  placeholder?: string;
  updateValue: (value: any) => void;
  value: any | null;
}) => {
  const { onChange, suggestions, loadingSuggestions, resetSuggestions } = useGeolocation();

  const handleSelectSuggestion = (suggestion: any) => {
    resetSuggestions();
    const context = suggestion?.context || [];

    const contextCountry = context.find(({ id }: any) => id.startsWith('country'));
    const contextRegion = context.find(({ id }: any) => id.startsWith('region'));
    const countryCode = contextCountry?.short_code.toLowerCase();
    const countryName = contextCountry?.text;

    const regionCode = contextRegion?.short_code?.toLowerCase();
    const regionName = contextRegion?.text;

    updateValue({
      ...suggestion,
      countryCode,
      countryName,
      regionCode,
      regionName,
    });
  };

  return (
    <>
      {label && (
        <SpanLabel>
          {label} {required && <Required />}
        </SpanLabel>
      )}

      {note && <Note>{note}</Note>}
      <Wrapper>
        {selectedLocation ? (
          <FakeInput className="fake-input">
            {selectedLocation.place_name}{' '}
            <button onClick={() => updateValue(null)}>
              <Close fontSize="small" />
            </button>
          </FakeInput>
        ) : (
          <>
            <InputBlock
              disabled={disabled}
              className={minimal ? styles.minimal : ''}
              placeholder={placeholder}
              value=""
              updateValue={onChange}
            />
            {(suggestions?.length > 0 || loadingSuggestions) && (
              <SuggestionWrapper className={minimal ? styles.minimal : ''}>
                {loadingSuggestions && (
                  <ProgressWrapper>
                    <CenteredProgress style={{ color: 'var(--darker)' }} size={20} />
                  </ProgressWrapper>
                )}
                {suggestions?.map((suggestion, index) => {
                  return (
                    <Suggestion
                      key={index}
                      onClick={() => {
                        handleSelectSuggestion(suggestion);
                      }}
                    >
                      {suggestion.place_name}
                    </Suggestion>
                  );
                })}
              </SuggestionWrapper>
            )}
          </>
        )}
      </Wrapper>
    </>
  );
};
