import { useRef, useState } from 'react';

export const useGeolocation = () => {
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const lastSearch = useRef('');

  const handleChange = async (val: string) => {
    if (lastSearch.current === val) {
      return;
    }
    lastSearch.current = val;
    setLoadingSuggestions(true);
    try {
      const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${val}.json?access_token=pk.eyJ1IjoibWF4Y2xvdXRpZXIyIiwiYSI6ImNsaXN5eXN0czAweHkzZHBka3FmMmgwZDgifQ.KkTAlYtW0HrRGOjbNhjjAA&autocomplete=true`;
      //const endpoint = `https://api.mapbox.com/search/searchbox/v1/suggest?q=${val}&access_token=pk.eyJ1IjoibWF4Y2xvdXRpZXIyIiwiYSI6ImNsaXN5eXN0czAweHkzZHBka3FmMmgwZDgifQ.KkTAlYtW0HrRGOjbNhjjAA&session_token=1`;
      const response = await fetch(endpoint);
      const results = await response.json();
      setSuggestions(results?.features);
      setLoadingSuggestions(false);
    } catch (error) {
      console.error('Error fetching data, ', error);
      setLoadingSuggestions(false);
    }
  };

  const resetSuggestions = () => {
    setSuggestions([]);
    lastSearch.current = '';
  };

  return {
    onChange: handleChange,
    suggestions,
    loadingSuggestions,
    setSuggestions,
    resetSuggestions,
  };
};
